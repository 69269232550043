<template>
  <div class="fleetDetail">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap px-8 md:px-14 justify-between">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div
            class="relative flex flex-col flex-wrap min-w-0 break-words w-full mb-6 border-0"
          >
            <div class="w-full">
              <div
                novalidate
                class="bg-blueGray-100 relative w-full rounded-md overflow-hidden shadow-lg mb-4"
              >
                <div class="rounded-t bg-white mb-0 px-6 py-6">
                  <div class="text-center flex justify-between items-center">
                    <h6
                      class="text-blueGray-700 text-xl font-bold flex items-center justify-between md:justify-start w-full"
                    >
                      {{ $t(selectedSection.label) }}
                      <button
                        @click="
                          openNewRow(sectionIndex, selectedSection.newType)
                        "
                        class="ml-2 bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      >
                        {{ $t("add new") }}
                      </button>
                    </h6>
                  </div>
                </div>
                <div class="flex">
                  <div class="w-64 border-r border-gray-200">
                    <div
                      class="bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded"
                      v-for="(section, index) in labelSections"
                      :key="section.label"
                      @click="selectSectionIndex(index)"
                      :class="{
                        'border-l-8 border-emerald-600': index == sectionIndex
                      }"
                    >
                      {{ section.label }}
                    </div>
                  </div>

                  <div
                    class="overflow-scroll w-full"
                    style="height: calc(100vh - 230px)"
                  >
                    <div v-if="'new' == editRow && sectionIndex == editSection">
                      <div class="px-10 pt-8">
                        <p class="font-semibold text-gray-500 mb-3">
                          {{ $t("preview label") }}:
                        </p>
                        <label-display v-if="formData.name" :label="formData" />
                        <div class="text-sm ml-5 font-semibold text-gray-500">
                          {{ formData.description }}
                        </div>
                      </div>
                      <LabelForm
                        @done="done"
                        :formData="formData"
                        :dispatcher="selectedSection.newDispatcher"
                        :error="$store.state.tags.error"
                        :item="selectedSection"
                      />
                    </div>
                    <div
                      v-for="(tag, index) in selectedSection.items"
                      :key="`${tag.id}-${selectedSection.label}`"
                      class="py-4 border-gray-50 border-t"
                      :class="{ 'bg-white': index % 2 != 0 }"
                    >
                      <div class="flex justify-between items-center px-7">
                        <div>
                          <label-display
                            :label="
                              index == editRow &&
                              selectedSection.sectionIndex == editSection
                                ? formData
                                : tag
                            "
                          />
                          <div
                            v-if="
                              index == editRow &&
                              selectedSection.sectionIndex == editSection
                            "
                            class="text-sm ml-5 font-semibold text-gray-500"
                          >
                            {{ formData.description }}
                          </div>
                          <div
                            v-else
                            class="text-sm ml-5 font-semibold text-gray-500"
                          >
                            {{ tag.description }}
                          </div>
                        </div>
                        <div>
                          <button
                            @click="openEditRow(sectionIndex, index, tag)"
                            class="mx-2 outline-none focus:outline-none"
                          >
                            <i class="fas fa-edit text-xs" /> {{ $t("edit") }}
                          </button>
                          <button
                            @click="deleteTag(tag)"
                            class="mx-2 outline-none focus:outline-none"
                          >
                            <i class="fas fa-trash-alt text-xs" />
                            {{ $t("delete") }}
                          </button>
                        </div>
                      </div>
                      <LabelForm
                        @done="done"
                        :formData="formData"
                        :dispatcher="selectedSection.dispatcher"
                        :error="$store.state.tags.error"
                        :item="selectedSection"
                        v-if="index == editRow && sectionIndex == editSection"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import div from "@/components/transitions/div.vue";
import LabelForm from "@/components/LabelForm.vue";
import LabelDisplay from "@/components/LabelDisplay.vue";
import utils from "@/mixins/utils";

export default {
  name: "LabelDetail",
  components: {
    // div,
    LabelForm,
    LabelDisplay
  },
  mixins: [utils],
  computed: {
    selectedSection() {
      return this.labelSections[this.sectionIndex];
    },
    labelSections() {
      return [
        {
          label: "asset status labels",
          items: this.$store.state.tags.bike_status_labels,
          dispatcher: "tags/updateBikeDamageTag",
          newDispatcher: "tags/upsertBikeDamageTag",
          newType: "bike",
          hasConditionals: true,
          bookableCondition: true
        },
        {
          label: "asset damage labels",
          items: this.$store.state.tags.bike_damage_tags,
          dispatcher: "tags/updateBikeDamageTag",
          newDispatcher: "tags/upsertBikeDamageTag",
          newType: "bike_damage"
          // hasConditionals: true,
          // bookableCondition: true
        },
        {
          label: "ticket labels",
          items: this.$store.state.tags.ticket_tags,
          dispatcher: "tags/updateBikeDamageTag",
          newDispatcher: "tags/upsertBikeDamageTag",
          newType: "ticket"
          // hasConditionals: true
        }
      ];
    }
  },
  data() {
    return {
      sectionIndex: 0,
      formData: {
        id: null,
        name: null,
        description: null,
        type: null,
        color: "#009966",
        custom_data: {}
      },
      editSection: null,
      editRow: null
    };
  },
  methods: {
    selectSectionIndex(index) {
      this.sectionIndex = index;
    },
    deleteTag({ id, name: label }) {
      this.$store.dispatch("tags/deleteLabel", { id, label });
      this.editRow = null;
      this.editSection = null;
    },
    openEditRow(
      section,
      row,
      { name, description, color, id, type, custom_data }
    ) {
      this.$store.commit("tags/RESET_ERROR");
      if (this.editRow == row && this.editSection == section) {
        this.editRow = null;
        this.editSection = null;
      } else {
        this.editRow = row;
        this.editSection = section;
        this.formData = {
          id,
          name,
          description,
          type,
          custom_data,
          color
        };
      }
    },
    openNewRow(section, type) {
      this.$store.commit("tags/RESET_ERROR");
      if (section == this.editSection && this.editRow == "new") {
        this.editRow = null;
        this.editSection = null;
      } else {
        this.editRow = "new";
        this.editSection = section;
        this.formData = {
          id: null,
          name: null,
          type,
          custom_data: {},
          description: null,
          color: "#009966"
        };
      }
    },
    done() {
      this.editRow = null;
      this.editSection = null;
    }
  },
  mounted() {
    this.$store.dispatch("tags/getAllTags");
  }
};
</script>
