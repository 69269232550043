<template>
  <div class="p-10 mt-5 border-t border-gray-50 bg-gray-50">
    <div
      v-if="error"
      class="bg-gradient-to-r from-rose-800 to-rose-600 text-rose-50 text-xl px-6 py-3 mb-8 font-semibold"
    >
      <i class="far fa-times-circle"></i> {{ error }}
    </div>
    <div class="flex items-center mb-2">
      <label class="uppercase text-blueGray-600 text-xs font-bold w-56">
        {{ $t("name") }}
      </label>
      <input
        v-model="formData.name"
        type="text"
        class="ml-1 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
      />
    </div>
    <div class="flex items-center mb-2">
      <label class="uppercase text-blueGray-600 text-xs font-bold w-56">
        {{ $t("description") }}
      </label>
      <input
        v-model="formData.description"
        style="width: calc(100% - 24rem)"
        type="text"
        class="ml-1 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
      />
    </div>
    <div class="flex items-center mb-2" v-if="damages.length > 0">
      <label class="uppercase text-blueGray-600 text-xs font-bold w-56">
        {{ $t("select related damages") }}
      </label>
      <el-select
        v-model="formData.children"
        multiple
        placeholder="Select"
        class="ml-1"
        style="width: calc(100% - 24rem)"
      >
        <el-option
          v-for="damage in damages"
          :key="damage.value"
          :label="damage.label"
          :value="damage.value"
          class="flex justify-between"
        >
          <span>{{ damage.label }}</span>
          <span v-if="damage.parent && damage.parent.id != formData.id">
            {{ $t("configured with") }}
            <span class="bg-rose-600 text-rose-50 px-2 rounded mr-6">
              {{ damage.parent.name }}
            </span>
          </span>
        </el-option>
      </el-select>
    </div>
    <div class="flex items-center mb-2" v-if="hasColor">
      <label class="uppercase text-blueGray-600 text-xs font-bold w-56">
        {{ $t("color") }}
      </label>
      <ColorForm :field="formData" />
    </div>

    <div class="flex mb-2" v-if="item && item.hasCustomFields">
      <label class="uppercase text-blueGray-600 text-xs font-bold w-56">
        {{ $t("custom fields") }}
      </label>
      <div class="" style="width: calc(100% - 24rem)">
        <div
          class="border rounded ml-1 shadow"
          v-if="formData.schema.columns && formData.schema.columns.length > 0"
        >
          <div
            class="flex"
            v-for="(column, index) in formData.schema.columns"
            :key="column.key"
            :class="{
              'border-t': index != 0
            }"
          >
            <div class="px-6 py-1 w-1/2 bg-white flex items-center">
              <div>
                <input
                  class="focus:outline-none w-full"
                  type="text"
                  v-model="column.name"
                  @change="handleAddKey(column.name, index)"
                  :placeholder="$t('Column name')"
                />
                <div
                  class="text-xs text-rose-600"
                  v-if="showValidation && column.name == ''"
                >
                  {{ $t("please fill in a valid name") }}
                </div>
              </div>
            </div>
            <div
              class="border-l px-6 py-2 bg-white w-1/2 flex justify-between items-center"
            >
              <el-select v-model="column.type" placeholder="Select">
                <el-option
                  v-for="t in customFieldTypes"
                  :key="t.value"
                  :label="$t(t.label)"
                  :value="t.value"
                />
              </el-select>
              <el-switch
                v-model="column.required"
                :active-text="$t('required')"
              >
              </el-switch>
              <el-popconfirm
                :title="$t('Are you sure to delete field?')"
                @confirm="confirmDeleteCustomField(index)"
              >
                <i
                  class="fas fa-trash-alt text-red-500 cursor-pointer"
                  slot="reference"
                />
              </el-popconfirm>
            </div>
          </div>
        </div>
        <button
          class="bg-emerald-500 text-white active:bg-rose-600 font-bold uppercase text-xs mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="addCustomField"
        >
          {{ $t("add custom field") }}
        </button>
      </div>
    </div>

    <div
      v-if="item && (item.bookableCondition || item.hasConditionals)"
      class="bg-blue-50 px-6 py-4 border-t border-b mt-4"
    >
      <label class="w-full uppercase text-blueGray-600 text-xs font-bold mr-2">
        {{ $t("custom conditionals") }}
      </label>
      <div v-if="item && item.bookableCondition">
        <!-- {{ formData.custom_data.can_self_book }} -->
        <el-switch
          v-model="formData.custom_data.can_self_book"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="can self book"
          inactive-text="remove self book"
        />
      </div>
      <!-- <div v-if="item && item.hasConditionals">has conditionals</div> -->
    </div>
    <button
      class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"
      @click="saveLabel"
    >
      {{ $t("save") }}
    </button>
    <button
      class="bg-rose-500 text-white active:bg-rose-600 font-bold uppercase text-xs ml-4 mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"
      @click="$emit('done')"
    >
      {{ $t("cancel") }}
    </button>
  </div>
</template>

<script>
import ColorForm from "@/components/ColorForm";

export default {
  name: "LabelForm",
  props: {
    formData: {
      type: Object,
      required: true
    },
    dispatcher: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: ""
    },
    damages: {
      type: Array,
      default: () => []
    },
    hasColor: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      require: true
    }
  },
  components: {
    ColorForm
  },
  data() {
    return {
      showValidation: false,
      customFieldTypes: [
        { label: "text", value: "text" },
        { label: "number", value: "number" },
        { label: "date time", value: "datetime" },
        { label: "address", value: "address" },
        { label: "time range", value: "timerange" },
        { label: "datepicker", value: "datepicker" }
      ]
    };
  },
  methods: {
    confirmDeleteCustomField(index) {
      this.formData.schema.columns = this.formData.schema.columns.filter(
        (_, i) => i != index
      );
    },
    handleAddKey(str, index) {
      let key = str.replace(/[^a-zA-Z0-9]/g, "_");
      this.formData.schema.columns[index].key = key.toLowerCase();
    },
    addCustomField() {
      this.formData.schema.columns.push({
        key: "",
        name: "",
        type: "text"
      });
    },
    async saveLabel() {
      if (
        this.formData?.schema?.columns &&
        this.formData.schema.columns.length > 0
      ) {
        if (
          this.formData.schema.columns.findIndex(col => col.key == "") != -1
        ) {
          this.showValidation = true;
          return;
        }
      }
      const res = await this.$store.dispatch(this.dispatcher, this.formData);
      if (res) {
        this.$emit("done");
        this.$notify({
          title: this.$t("Success"),
          message: this.$t("saved"),
          type: "success"
        });
      }
    }
  },
  created() {
    if (
      this.item?.bookableCondition == true &&
      this.formData?.custom_data?.can_self_book == undefined
    ) {
      this.$set(this.formData.custom_data, "can_self_book", true);
      // this.formData.custom_data.can_self_book = true;
    }
  }
};
</script>
