var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fleetDetail"},[_c('div',{staticClass:"fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12"}),_c('div',{staticClass:"flex flex-wrap px-8 md:px-14 justify-between"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"relative flex flex-col flex-wrap min-w-0 break-words w-full mb-6 border-0"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-blueGray-100 relative w-full rounded-md overflow-hidden shadow-lg mb-4",attrs:{"novalidate":""}},[_c('div',{staticClass:"rounded-t bg-white mb-0 px-6 py-6"},[_c('div',{staticClass:"text-center flex justify-between items-center"},[_c('h6',{staticClass:"text-blueGray-700 text-xl font-bold flex items-center justify-between md:justify-start w-full"},[_vm._v(" "+_vm._s(_vm.$t(_vm.selectedSection.label))+" "),_c('button',{staticClass:"ml-2 bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150",on:{"click":function($event){return _vm.openNewRow(_vm.sectionIndex, _vm.selectedSection.newType)}}},[_vm._v(" "+_vm._s(_vm.$t("add new"))+" ")])])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-64 border-r border-gray-200"},_vm._l((_vm.labelSections),function(section,index){return _c('div',{key:section.label,staticClass:"bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded",class:{
                      'border-l-8 border-emerald-600': index == _vm.sectionIndex
                    },on:{"click":function($event){return _vm.selectSectionIndex(index)}}},[_vm._v(" "+_vm._s(section.label)+" ")])}),0),_c('div',{staticClass:"overflow-scroll w-full",staticStyle:{"height":"calc(100vh - 230px)"}},[('new' == _vm.editRow && _vm.sectionIndex == _vm.editSection)?_c('div',[_c('div',{staticClass:"px-10 pt-8"},[_c('p',{staticClass:"font-semibold text-gray-500 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("preview label"))+": ")]),(_vm.formData.name)?_c('label-display',{attrs:{"label":_vm.formData}}):_vm._e(),_c('div',{staticClass:"text-sm ml-5 font-semibold text-gray-500"},[_vm._v(" "+_vm._s(_vm.formData.description)+" ")])],1),_c('LabelForm',{attrs:{"formData":_vm.formData,"dispatcher":_vm.selectedSection.newDispatcher,"error":_vm.$store.state.tags.error,"item":_vm.selectedSection},on:{"done":_vm.done}})],1):_vm._e(),_vm._l((_vm.selectedSection.items),function(tag,index){return _c('div',{key:((tag.id) + "-" + (_vm.selectedSection.label)),staticClass:"py-4 border-gray-50 border-t",class:{ 'bg-white': index % 2 != 0 }},[_c('div',{staticClass:"flex justify-between items-center px-7"},[_c('div',[_c('label-display',{attrs:{"label":index == _vm.editRow &&
                            _vm.selectedSection.sectionIndex == _vm.editSection
                              ? _vm.formData
                              : tag}}),(
                            index == _vm.editRow &&
                            _vm.selectedSection.sectionIndex == _vm.editSection
                          )?_c('div',{staticClass:"text-sm ml-5 font-semibold text-gray-500"},[_vm._v(" "+_vm._s(_vm.formData.description)+" ")]):_c('div',{staticClass:"text-sm ml-5 font-semibold text-gray-500"},[_vm._v(" "+_vm._s(tag.description)+" ")])],1),_c('div',[_c('button',{staticClass:"mx-2 outline-none focus:outline-none",on:{"click":function($event){return _vm.openEditRow(_vm.sectionIndex, index, tag)}}},[_c('i',{staticClass:"fas fa-edit text-xs"}),_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('button',{staticClass:"mx-2 outline-none focus:outline-none",on:{"click":function($event){return _vm.deleteTag(tag)}}},[_c('i',{staticClass:"fas fa-trash-alt text-xs"}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])])]),(index == _vm.editRow && _vm.sectionIndex == _vm.editSection)?_c('LabelForm',{attrs:{"formData":_vm.formData,"dispatcher":_vm.selectedSection.dispatcher,"error":_vm.$store.state.tags.error,"item":_vm.selectedSection},on:{"done":_vm.done}}):_vm._e()],1)})],2)])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }