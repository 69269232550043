<template>
  <div>
    <div class="flex items-center">
      <div
        class="w-8 h-8 rounded mx-1 mr-2 flex items-center justify-center cursor-pointer"
        :style="`background: ${field.color};color:${invertColor(
          field.color,
          true
        )}`"
        @click="randomColor(field)"
      >
        <i class="fas fa-random"></i>
      </div>
      <input
        v-model="field.color"
        type="text"
        class="mr-1 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring w-44 ease-linear transition-all duration-150"
      />
    </div>
    <div class="flex flex-wrap mt-2 cursor-pointer">
      <div
        class="w-8 h-8 rounded m-1"
        v-for="color in colors"
        :key="color"
        :class="{
          'shadow-2xl': color == field.color
        }"
        @click="setColor(field, color)"
        :style="`background: ${color}`"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/mixins/utils";
export default {
  name: "ColorForm",
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  mixins: [utils],
  data() {
    return {
      colors: [
        "#009966",
        "#8fbc8f",
        "#3cb371",
        "#00b140",
        "#013220",
        "#6699cc",
        "#0000ff",
        "#e6e6fa",
        "#9400d3",
        "#330066",
        "#808080",
        "#36454f",
        "#f7e7ce",
        "#c21e56",
        "#cc338b",
        "#dc143c",
        "#ff0000",
        "#cd5b45",
        "#eee600",
        "#ed9121",
        "#c39953"
      ]
    };
  },
  methods: {
    setColor(field, color) {
      field.color = color;
    },
    randomColor(field) {
      const n = (Math.random() * 0xfffff * 1000000).toString(16);
      field.color = "#" + n.slice(0, 6);
    }
  }
};
</script>
