<template>
  <div>
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap px-8 md:px-14 justify-between">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div
            class="relative flex flex-col flex-wrap min-w-0 break-words w-full mb-6 border-0"
          >
            <div class="w-full">
              <div
                novalidate
                class="bg-blueGray-100 relative w-full rounded-md overflow-hidden shadow-lg mb-4"
              >
                <div class="rounded-t bg-white mb-0 px-6 py-6">
                  <div class="text-center flex justify-between items-center">
                    <h6
                      class="text-blueGray-700 text-xl font-bold flex items-center justify-between md:justify-start w-full"
                    >
                      {{ $t(selectedSection.label) }}
                      <button
                        @click="
                          openNewRow(
                            selectedSection.sectionIndex,
                            selectedSection.newType
                          )
                        "
                        class="ml-2 bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      >
                        {{ $t("add new") }}
                      </button>
                    </h6>
                  </div>
                </div>
                <div class="flex">
                  <div class="w-64 border-r border-gray-200">
                    <div
                      class="bg-white px-3 py-2 my-3 mx-4 relative font-semibold cursor-pointer rounded"
                      v-for="(section, index) in labelSections"
                      :key="section.label"
                      @click="selectSectionIndex(index)"
                      :class="{
                        'border-l-8 border-emerald-600': index == sectionIndex
                      }"
                    >
                      {{ section.label }}
                    </div>
                  </div>

                  <div
                    class="overflow-scroll w-full"
                    style="height: calc(100vh - 230px)"
                  >
                    <div
                      v-if="
                        'new' == editRow &&
                        selectedSection.sectionIndex == editSection
                      "
                    >
                      <LabelForm
                        @done="done"
                        :formData="formData"
                        :item="selectedSection"
                        :dispatcher="selectedSection.newDispatcher"
                        :error="$store.state.typeDefinitions.error"
                        :hasColor="false"
                      />
                    </div>
                    <div
                      v-for="(typeDefinition, index) in selectedSection.items"
                      :key="`${typeDefinition.id}-${selectedSection.label}`"
                      class="py-4 border-gray-200 border-b"
                      :class="{ 'bg-white': index % 2 != 0 }"
                    >
                      <div class="flex justify-between items-center px-7">
                        <div>
                          <div class="font-semibold text-gray-700">
                            {{ typeDefinition.name }}
                          </div>
                          <div class="text-sm ml-5 font-semibold text-gray-500">
                            {{ typeDefinition.description }}
                          </div>
                          <div
                            v-if="selectedSection.damages"
                            class="flex flex-wrap"
                          >
                            <div
                              class="text-xs text-rose-700 text-bold mx-2"
                              v-if="
                                typeDefinition.children &&
                                typeDefinition.children.length == 0
                              "
                            >
                              {{ $t("no damages configurated") }}
                            </div>
                            <div
                              class="rounded mb-2 ml-2 px-2 text-gray-500 bg-gray-50 border text-xs"
                              v-for="child in typeDefinition.children"
                              :key="child.id + 'typedef child'"
                            >
                              {{ child.name }}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            @click="
                              openEditRow(sectionIndex, index, typeDefinition)
                            "
                            class="mx-2 outline-none focus:outline-none"
                          >
                            <i class="fas fa-edit text-xs" /> {{ $t("edit") }}
                          </button>
                          <button
                            @click="deleteTag(typeDefinition)"
                            class="mx-2 outline-none focus:outline-none"
                          >
                            <i class="fas fa-trash-alt text-xs" />
                            {{ $t("delete") }}
                          </button>
                        </div>
                      </div>
                      <LabelForm
                        @done="done"
                        :formData="formData"
                        :dispatcher="selectedSection.dispatcher"
                        :item="selectedSection"
                        :error="$store.state.typeDefinitions.error"
                        :hasColor="false"
                        :damages="
                          selectedSection.damages
                            ? selectedSection.damages
                                .map(d => ({
                                  label: d.name,
                                  value: d.id,
                                  parent: d.parent
                                }))
                                .filter(
                                  d =>
                                    !(
                                      d.parent != null &&
                                      d.parent.id != formData.id
                                    )
                                )
                            : []
                        "
                        v-if="index == editRow && sectionIndex == editSection"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import div from "@/components/transitions/div.vue";
import LabelForm from "@/components/LabelForm.vue";

export default {
  name: "LabelDetail",
  components: {
    // div,
    LabelForm
  },
  computed: {
    selectedSection() {
      return this.labelSections[this.sectionIndex];
    },
    labelSections() {
      return [
        {
          label: "asset types",
          items: this.$store.state.typeDefinitions.bike,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "bike",
          damages: this.$store.state.typeDefinitions.bike_damage_definitions,
          hasCustomFields: true
        },
        {
          label: "component types",
          items: this.$store.state.typeDefinitions.components,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "components"
          // damages: this.$store.state.typeDefinitions.component_damages,
          // hasCustomFields: true
          // hasCustomFields: true
        },
        {
          label: "component damage types",
          items: this.$store.state.typeDefinitions.component_damages,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "component_damages"
          // hasCustomFields: true
        },
        {
          label: "damage types",
          items: this.$store.state.typeDefinitions.bike_damage_definitions,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "bike_damage"
        },
        {
          label: "ticket types",
          items: this.$store.state.typeDefinitions.ticketDefinitions,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "ticket"
        },
        {
          label: "supplier types",
          items: this.$store.state.typeDefinitions.supplier,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "supplier"
        },
        {
          label: "hub types",
          items: this.$store.state.typeDefinitions.hub,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "hub"
        },
        {
          label: "check list types",
          items: this.$store.state.typeDefinitions.checklist,
          dispatcher: "typeDefinitions/updateBikeDamageDefinition",
          newDispatcher: "typeDefinitions/insertBikeDamageDefinition",
          newType: "checklist"
        }
      ];
    }
  },
  data() {
    return {
      sectionIndex: 0,
      formData: {
        id: null,
        name: null,
        description: null,
        type: null,
        schema: {
          columns: []
        },
        children: []
      },
      editSection: null,
      editRow: null
    };
  },
  methods: {
    selectSectionIndex(index) {
      this.sectionIndex = index;
    },
    deleteTag({ id, name: typeDefinition }) {
      this.$store.dispatch("typeDefinitions/deleteTypeDefinition", {
        id,
        typeDefinition
      });
      this.editRow = null;
      this.editSection = null;
    },
    openEditRow(
      section,
      row,
      { name, description, id, type, children, schema }
    ) {
      if (schema?.columns == undefined) {
        schema = {
          columns: []
        };
      }
      this.$store.commit("typeDefinitions/RESET_ERROR");
      if (this.editRow == row && this.editSection == section) {
        this.editRow = null;
        this.editSection = null;
      } else {
        this.editRow = row;
        this.editSection = section;
        this.formData = {
          id,
          name,
          description,
          type,
          schema,
          children: children.map(c => c.id)
        };
      }
    },
    openNewRow(section, type) {
      this.$store.commit("typeDefinitions/RESET_ERROR");
      if (section == this.editSection && this.editRow == "new") {
        this.editRow = null;
        this.editSection = null;
      } else {
        this.editRow = "new";
        this.editSection = section;
        this.formData = {
          id: null,
          name: null,
          type,
          schema: {
            columns: []
          },
          description: null,
          children: []
        };
      }
    },
    done() {
      this.editRow = null;
      this.editSection = null;
    }
  },
  mounted() {
    this.$store.dispatch("typeDefinitions/getAllTypeDefinitions");
  }
};
</script>
